<template>
  <div alert-data-vuetify>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <link href="https://fonts.googleapis.com/css2?family=Roboto+Flex:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap" rel="stylesheet">
    <link href="https://cdn.jsdelivr.net/npm/@mdi/font@7.x/css/materialdesignicons.min.css" rel="stylesheet">
    <v-app class="rounded transparent">
      <v-main>
        <AlertBanner :districtCode="districtCode || 1010" :positionTop="positionTop || false"/>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import AlertBanner from './components/AlertBanner.vue'

export default {
  name: 'App',
  components: {
    AlertBanner
  },
  props: {
    districtCode: Number,
    positionTop: Boolean,
  }
}
</script>

<style scoped>
[alert-data-vuetify] {
  overflow-y: hidden !important
}

::v-deep .v-application,
::v-deep .text-subtitle-1 {
  font-family: 'Roboto Flex', sans-serif;
}

::v-deep div.v-application,
::v-deep div.v-application--wrap,
::v-deep .v-main__wrap {
  position: initial !important
}

::v-deep div.v-application--wrap {
  min-height: fit-content !important
}

::v-deep .container--fluid {
  padding: 0px !important
}

:global(div>.rounded) {
  border-radius: 4px !important
}

:global(.transparent) {
  background-color: transparent !important;
  border-color: transparent !important
}

:global(html) {
  scroll-padding-top: 150px
}
</style>
